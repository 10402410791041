import i18n from '@/plugins/i18n'

const validator = {
  message(field) {
    return (
      field +
      ' muss jedes folgenden Felder enthalten: ' +
      [
        i18n.t('application.fields.joboffer'),
        i18n.t('application.fields.salutation'),
        i18n.t('application.fields.first_name'),
        i18n.t('application.fields.last_name'),
        i18n.t('application.fields.email')
      ].join(', ')
    )
  },
  validate(value) {
    const required = [
      'joboffer',
      'salutation',
      'first_name',
      'last_name',
      'email'
    ]

    for (var i = 0; i < required.length; i++) {
      if (!value?.fields?.some((field) => field.model === required[i])) {
        // if one required model is not found in the field, we fail the validation
        return false
      }
    }

    // if we reach this, every element was contained and the value is valid
    return true
  }
}
export default validator
