import Vue from 'vue'

Vue.filter('toFixed', function (value, length = 2) {
  if (!value) return ''

  return value.toFixed(length)
})

Vue.filter('number', function (value, fractionDigits) {
  return (value || 0).toLocaleString('de-DE', {
    minimumFractionDigits: fractionDigits ? fractionDigits : undefined,
    maximumFractionDigits: fractionDigits ? fractionDigits : undefined
  })
})
