<template>
  <div>
    <v-list nav dense>
      <v-list-item :disabled="loadingLogout" @click="logout">
        <v-list-item-action>
          <v-progress-circular
            v-if="loadingLogout"
            indeterminate
            size="16"
            width="2"
            color="primary"
          ></v-progress-circular>
          <v-icon v-else small>mdi-logout-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('misc.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <app-navigation-list-base :items="items"></app-navigation-list-base>
  </div>
</template>

<script>
import AppNavigationListBase from './AppNavigationListBase.vue'

export default {
  name: 'AppNavigationDrawerListUser',

  components: {
    AppNavigationListBase
  },

  data() {
    return {
      loadingLogout: false
    }
  },

  computed: {
    items() {
      return [
        {
          routeName: 'account',
          icon: 'mdi-account',
          title: this.$t('navigation.account')
        },
        {
          routeName: 'security',
          icon: 'mdi-lock',
          title: this.$t('navigation.security')
        }
      ]
    }
  },

  methods: {
    logout() {
      this.loadingLogout = true

      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push({ name: 'login' })
          this.loadingLogout = false
        })
        .catch(() => {
          this.$router.push({ name: 'login' })
          this.loadingLogout = false
        })
    }
  }
}
</script>
