<template>
  <app-navigation-list-base :items="items"></app-navigation-list-base>
</template>

<script>
import { NAVIGATION_COUNTS_QUERY } from '@/graphql/queries.js'

import AppNavigationListBase from './AppNavigationListBase.vue'

export default {
  name: 'AppNavigationDrawerListMain',

  components: {
    AppNavigationListBase
  },

  apollo: {
    counts: {
      query: NAVIGATION_COUNTS_QUERY,
      update: (data) => data.me || {},
      pollInterval: 1000 * 30 // 30 sec
    }
  },

  data() {
    return {
      counts: {}
    }
  },

  computed: {
    items() {
      return [
        {
          routeName: 'clients',
          icon: 'mdi-domain',
          title: this.$t('navigation.clients')
        },
        {
          routeName: 'dashboard',
          icon: 'mdi-view-dashboard',
          title: this.$t('navigation.dashboard')
        },
        {
          icon: 'mdi-briefcase-account',
          title: this.$t('navigation.folders.application'),
          group: '/application/',
          children: [
            {
              routeName: 'candidates',
              title: this.$t('navigation.candidates'),
              badge: this.counts.client?.candidates_count
            },
            {
              routeName: 'customforms',
              title: this.$t('navigation.customforms')
            },
            {
              routeName: 'videoinfos',
              title: this.$t('navigation.videoinfos')
            },
            {
              routeName: 'joboffers',
              title: this.$t('navigation.joboffers'),
              badge: this.counts.client?.joboffers_count
            },
            {
              routeName: 'application-statistics',
              title: this.$t('navigation.application-statistics')
            }
          ]
        },
        {
          icon: 'mdi-island',
          title: this.$t('navigation.folders.absences'),
          group: /^\/absences/, // regex: has to start (^) with "/absences"
          children: [
            {
              routeName: 'absencecalendar',
              title: this.$t('navigation.absencecalendar')
            },
            {
              routeName: 'absencerequests',
              title: this.$t('navigation.absencerequests'),
              badge: this.counts.absencerequests_count
            }
          ]
        },
        {
          icon: 'mdi-timetable',
          title: this.$t('navigation.folders.timetracking'),
          group: /^\/timetracking/, // regex: has to start (^) with "/timetracking"
          children: [
            {
              routeName: 'timetrackingtable',
              title: this.$t('navigation.timetrackingtable')
            },
            {
              routeName: 'timetrackingcalendar',
              title: this.$t('navigation.timetrackingcalendar')
            },
            {
              routeName: 'timetracking-reports',
              title: this.$t('navigation.timetracking-reports')
            }
          ]
        },
        {
          icon: 'mdi-calendar-clock',
          title: this.$t('navigation.folders.shifts'),
          group: /^\/shifts/, // regex: has to start (^) with "/shifts"
          children: [
            {
              routeName: 'shiftscheduler',
              title: this.$t('navigation.shiftscheduler')
            },
            {
              routeName: 'shifts-reports',
              title: this.$t('navigation.shifts-reports')
            }
          ]
        },
        {
          routeName: 'administration',
          icon: 'mdi-cog',
          title: this.$t('navigation.folders.administration')
        }
      ]
    }
  }
}
</script>
