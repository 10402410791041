import Vue from 'vue'

Vue.filter('currency', function (value, returnNullIfEmpty) {
  if (returnNullIfEmpty && (value === null || value === undefined)) {
    return null
  } else {
    return (value || 0).toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR'
    })
  }
})
