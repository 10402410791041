<template>
  <v-main>
    <v-container
      :fluid="fluid"
      :class="{ 'fill-height': fillHeight, 'px-0': $vuetify.breakpoint.mobile }"
    >
      <alert-unsupported-browser></alert-unsupported-browser>

      <transition name="fade-fast" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-container>

    <!-- global progress -->
    <!--
    <progress-dialog></progress-dialog>
    <progress-snackbar></progress-snackbar>
    -->
  </v-main>
</template>

<script>
import _ from 'lodash'

import AlertUnsupportedBrowser from '@/layout/AlertUnsupportedBrowser.vue'
// import ProgressDialog from '@/components/ProgressDialog.vue'
// import ProgressSnackbar from '@/components/ProgressSnackbar.vue'

export default {
  name: 'AppContent',

  components: {
    AlertUnsupportedBrowser
    // ProgressDialog,
    // ProgressSnackbar
  },

  computed: {
    fillHeight() {
      return _.get(this.$route, 'meta.layout.fillHeight', false)
    },

    fluid() {
      return _.get(this.$route, 'meta.layout.fluid', false)
    }
  }
}
</script>
