import axios from 'axios'
import log from 'loglevel'
import store from '@/store'

const urlPrefix = process.env.VUE_APP_API_URL + '/auth'

export default {
  login({ email, password, token }) {
    return new Promise((resolve, reject) => {
      const client = store.getters['client_id']

      axios
        .post(
          urlPrefix + '/login',
          token ? { token, client } : { email, password, client }
        )
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },

  logout() {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/logout')
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          log.error(err)
          reject(err)
        })
    })
  },

  register({ email, password } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/register', {
          email,
          password
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          log.error(err)

          reject(err)
        })
    })
  },

  requestResetPassword({ email } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/request-password-reset', {
          email: email
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          log.error(err)

          reject(err)
        })
    })
  },

  resetPassword({ token, email, password } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/reset-password', {
          token: token,
          email: email,
          password: password
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          log.error(err)

          reject(err)
        })
    })
  },

  resendVerfication({ email } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/resend-verification', {
          email: email
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          log.error(err)

          reject(err)
        })
    })
  },

  verify({ token } = {}) {
    return new Promise((resolve, reject) => {
      axios
        .post(urlPrefix + '/verify', {
          token: token
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          log.error(err)

          reject(err)
        })
    })
  }
}
