<template>
  <span v-bind="$attrs">{{ text }}</span>
</template>

<script>
import gql from 'graphql-tag'
import { mapState } from 'vuex'

export default {
  name: 'Version',

  apollo: {
    healthcheck: {
      query: gql`
        query {
          healthcheck {
            status
            version {
              version
            }
          }
        }
      `,
      pollInterval: 1000 * 60 * 5, // every 5 minutes
      error() {
        this.$toasted.error(this.$t('messages.backend.error'), {
          duration: '10000',
          keepOnHover: true
        })
      }
    }
  },

  data() {
    return {
      healthcheck: {}
    }
  },

  computed: {
    ...mapState(['version', 'versionApi', 'stage']),

    text() {
      let textVersion = 'ui-' + this.version
      let textVersionApi = this.versionApi ? 'api-' + this.versionApi : null
      let textStage = this.stage

      // Join versions with '|' only if they are set
      return [textVersion, textVersionApi, textStage]
        .filter(Boolean)
        .join(' | ')
    },

    versionApi() {
      return this.healthcheck?.version?.version
    }
  }
}
</script>
