<template>
  <v-list nav dense>
    <template v-for="item in visibleItems">
      <!-- item with children -->
      <v-list-group
        v-if="item.children"
        :key="item.title"
        :prepend-icon="item.icon"
        :disabled="item.disabled"
        :group="item.group"
      >
        <template #activator>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </template>

        <template v-for="subitem in item.children">
          <!-- item with children -->
          <v-list-group
            v-if="subitem.children"
            :key="subitem.title"
            :disabled="subitem.disabled"
            :group="subitem.group"
            no-action
            sub-group
          >
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>{{ subitem.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-for="subitem2 in subitem.children">
              <v-list-item
                :key="subitem2.routeName"
                :to="subitem2.routeName ? { name: subitem2.routeName } : null"
                :disabled="subitem2.disabled"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ subitem2.title }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon small>
                    {{ subitem2.icon }}
                  </v-icon>
                </v-list-item-action>

                <v-chip v-if="subitem2.badge" x-small color="info">{{
                  subitem2.badge
                }}</v-chip>
              </v-list-item>
            </template>
          </v-list-group>

          <!-- single item -->
          <v-list-item
            v-else
            :key="subitem.routeName"
            :to="subitem.routeName ? { name: subitem.routeName } : null"
            :disabled="subitem.disabled"
          >
            <v-list-item-action>
              <v-icon small>
                {{ subitem.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ subitem.title }}</v-list-item-title>
            </v-list-item-content>

            <v-chip v-if="subitem.badge" x-small color="info">{{
              subitem.badge
            }}</v-chip>
          </v-list-item>
        </template>
      </v-list-group>

      <!-- single item -->
      <v-list-item
        v-else
        :key="item.routeName"
        :to="item.routeName ? { name: item.routeName } : null"
        :disabled="item.disabled"
      >
        <v-list-item-action>
          <v-icon small>
            {{ item.icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>

        <v-chip v-if="item.badge" x-small color="info">{{ item.badge }}</v-chip>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'AppNavigationDrawerListBase',

  props: {
    items: {
      type: Array,
      required: true
    }
  },

  computed: {
    ...mapGetters('auth', ['roles', 'permissions']),

    visibleItems() {
      return this.items ? this.filterChildren(this.items) : []
    }
  },

  methods: {
    isVisible(routeName) {
      if (!routeName) {
        // if there is no routeName, it should be visible (placeholder)
        return true
      }

      const resolvedRoute = this.$router.resolve({ name: routeName }).resolved
      if (resolvedRoute) {
        const routeRoles = resolvedRoute.meta.requiresRoles
        const routePermissions = resolvedRoute.meta.requiresPermissions

        return (
          this.isVisibleRoles(routeRoles) &&
          this.isVisiblePermissions(routePermissions)
        )
      } else {
        // if there is no route, it should be visible (placeholder)
        return true
      }
    },

    isVisibleRoles(requiredRoles) {
      if (this.roles && requiredRoles) {
        return this.roles.some((item) => requiredRoles.includes(item))
      } else {
        // if there is no requiredRoles, it should be visible
        return true
      }
    },

    isVisiblePermissions(requiredPermissions) {
      if (this.permissions && requiredPermissions) {
        return this.permissions.some((item) =>
          requiredPermissions.includes(item)
        )
      } else {
        // if there is no requiredPermissions, it should be visible
        return true
      }
    },

    filterChildren(children) {
      if (!children || children.length === 0) {
        return []
      }

      const mappedChildren = children.map((item) => {
        if (!item.children) {
          // the item has no children, so we will check if it should be visible or not
          return this.isVisible(item.routeName) ? item : null
        } else {
          // the item has children, so we will check the children
          const children = this.filterChildren(item.children)
          if (children && children.length > 0) {
            return {
              /*
              routeName: item.routeName,
              icon: item.icon,
              title: item.title,
              disabled: item.disabled,
              group: item.group
               */
              ...item,
              children: children
            }
          } else {
            return null
          }
        }
      })

      // remove falsy (null, ...) values from array
      return _.compact(mappedChildren)
    }
  }
}
</script>
