<template>
  <v-navigation-drawer
    v-if="renderRouterView"
    v-model="sideSheet"
    app
    clipped
    right
    disable-resize-watcher
  >
    <router-view name="sideSheet"></router-view>
  </v-navigation-drawer>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'AppSideSheet',

  computed: {
    renderRouterView() {
      return !!_.get(this.$route, 'matched[0].components.sideSheet', false)
    },
    sideSheet: {
      get() {
        return this.$store.state.layout.sideSheet
      },
      set(val) {
        this.$store.commit('layout/setSideSheet', val)
      }
    }
  }
}
</script>
