<template>
  <v-btn-toggle v-model="dark" v-bind="$attrs" mandatory>
    <!-- Light -->
    <v-btn :value="false">
      Light
      <v-icon right>mdi-weather-sunny</v-icon>
    </v-btn>
    <!-- Dark -->
    <v-btn :value="true">
      Dark
      <v-icon right>mdi-weather-night</v-icon>
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'ThemeSwitcher',

  computed: {
    // vuex two-way binding
    dark: {
      get() {
        return this.$store.state.dark
      },
      set(val) {
        this.$store.commit('setDark', val)
      }
    }
  }
}
</script>
