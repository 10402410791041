<template>
  <card-base
    :loading="internalLoading"
    :disabled="internalLoading"
    v-bind="$attrs"
  >
    <v-row align="center">
      <v-col class="shrink">
        <v-btn
          color="primary"
          fab
          :large="!dense"
          :small="!!dense"
          @click="onSubmit"
        >
          <v-icon v-if="running">mdi-stop</v-icon>
          <v-icon v-else>mdi-play</v-icon>
        </v-btn>
      </v-col>

      <v-col
        v-if="running"
        :class="[
          'grow',
          { 'text-body-1': !dense },
          { 'text-caption': !!dense }
        ]"
      >
        {{ timerecord.start | dateAndTime }} <br />
        {{ $t('timerecorder.duration') }}: {{ timeSinceStart | timer }}
      </v-col>
      <v-col
        v-else
        :class="[
          'grow',
          { 'text-body-1': !dense },
          { 'text-caption': !!dense }
        ]"
      >
        {{ $t('timerecorder.not-running-info') }}
      </v-col>
    </v-row>
  </card-base>
</template>

<script>
import gql from 'graphql-tag'
import { mapGetters } from 'vuex'
import DateUtils from '@/utils/date.js'

import { CardBase } from '@freshsolutions/fresh-vue-cards'

export default {
  name: 'Timerecorder',

  components: {
    CardBase
  },

  props: {
    dense: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  apollo: {
    timerecord: {
      query: gql`
        query me {
          me {
            id
            activeTimerecord {
              id
              start
              end
            }
          }
        }
      `,
      update: (data) => data.me?.activeTimerecord ?? null,
      fetchPolicy: 'network-only'
    }
  },

  data() {
    return {
      loading: false,

      timerecord: null,

      interval: null,
      now: new Date()
    }
  },
  computed: {
    ...mapGetters({ authUser: 'auth/user' }),

    running() {
      return !!this.timerecord?.id
    },

    timeSinceStart() {
      if (this.running) {
        const now = this.now.getTime()
        const start = new Date(this.timerecord.start).getTime()

        return now - start
      } else {
        return null
      }
    },

    internalLoading() {
      return this.loading || this.$apollo.queries.timerecord.loading
    }
  },

  mounted() {
    this.interval = setInterval(() => (this.now = new Date()), 100) // it's so small to reduce unsmooth counting
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
    onSubmit() {
      if (!this.running) {
        this.start()
      } else {
        this.stop()
      }
    },

    start() {
      this.loading = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation upsertTimerecord($input: TimerecordUpsertInput!) {
              upsertTimerecord(input: $input) {
                id
                start
                end
              }
            }
          `,
          variables: {
            input: {
              user: {
                connect: this.authUser.id
              },
              // project: {
              //   connect: this.project
              // },
              // activity: {
              //   connect: this.activity
              // },
              // tags: {
              //   sync: this.tags ?? undefined
              // },
              start: DateUtils.getDateAndTimeString(new Date())
              // end: DateUtils.getDateAndTimeString(new Date())
              // description: this.description
            }
          }
        })
        .then(() => {
          this.loading = false
          this.$apollo.queries.timerecord.refetch()
          this.$emit('submit:success')
        })
        .catch(() => {
          this.loading = false
          this.$emit('submit:fail')
        })
    },

    stop() {
      this.loading = true

      this.$apollo
        .mutate({
          mutation: gql`
            mutation upsertTimerecord($input: TimerecordUpsertInput!) {
              upsertTimerecord(input: $input) {
                id
                start
                end
              }
            }
          `,
          variables: {
            input: {
              id: this.timerecord.id,
              user: {
                connect: this.authUser.id
              },
              // project: {
              //   connect: this.project
              // },
              // activity: {
              //   connect: this.activity
              // },
              // tags: {
              //   sync: this.tags ?? undefined
              // },
              start: DateUtils.getDateAndTimeString(this.timerecord.start),
              end: DateUtils.getDateAndTimeString(new Date())
              // description: this.description
            }
          }
        })
        .then(() => {
          this.loading = false
          this.$apollo.queries.timerecord.refetch()
          this.$emit('submit:success')
        })
        .catch(() => {
          this.loading = false
          this.$emit('submit:fail')
        })
    }
  }
}
</script>
