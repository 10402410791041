import Vue from 'vue'

import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import extractFiles from 'extract-files/extractFiles.mjs'
import isExtractableFile from 'extract-files/isExtractableFile.mjs'
import VueApollo from 'vue-apollo'
import store from '@/store'

Vue.use(VueApollo)

const cache = new InMemoryCache()
// const link = new BatchHttpLink({ uri: process.env.VUE_APP_GRAPHQL_HTTP })
const opts = { uri: process.env.VUE_APP_GRAPHQL_HTTP }
const link = ApolloLink.split(
  (operation) => extractFiles(operation, isExtractableFile).files.size > 0,
  createUploadLink(opts),
  new BatchHttpLink(opts)
)
const authLink = setContext((_, { headers }) => {
  const token = store.state.auth.token // get token from vuex

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

export const apolloClient = new ApolloClient({
  link: authLink.concat(link),
  cache: cache,
  shouldBatch: true
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})
