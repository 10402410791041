export default {
  getClientFromDomain() {
    let host = window.location.host
    let hostParts = host.split('.')

    /**
     * We need at least a length of three:
     *
     * 1. Subdomain (the client name)
     * 2. SLD (personalmarkt.online)
     * 3. TLD (de)
     *
     * If we are not on the production environment we can have more than three parts:
     * 1. Subdomain (the client name)
     * 2. ... more subdomains (for example: dev)
     * 3. ... more subdomains (for example: it)
     * 4. SLD (personalmarkt.online)
     * 5. TLD (de)
     *
     * But it is always the first one which we are interested in (the client name).
     */
    if (hostParts.length >= 3) {
      let client = hostParts[0]
      return client
    } else {
      return null
    }
  },

  redirectToClient(client) {
    if (client) {
      const protocol = window.location.protocol
      const host = window.location.host
      const baseHost = host.substring(host.indexOf('.') + 1, host.length)
      const url = `${protocol}//${client}.${baseHost}`

      window.open(url, '_blank')
    }
  }
}
