export default {
  namespaced: true,

  state: {
    navigationDrawer: null,
    sideSheet: false
  },
  mutations: {
    setNavigationDrawer(state, navigationDrawer) {
      state.navigationDrawer = navigationDrawer
    },
    setSideSheet(state, sideSheet) {
      state.sideSheet = sideSheet
    }
  },
  actions: {
    toggleNavigationDrawer({ state, commit }) {
      commit('setNavigationDrawer', !state.navigationDrawer)
    },
    toggleSideSheet({ state, commit }) {
      commit('setSideSheet', !state.sideSheet)
    }
  },
  getters: {}
}
