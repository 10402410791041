import Vue from 'vue'
import Vuetify, { VSelect, VExpansionPanels } from 'vuetify/lib'
import { VuetifyIcons as FreshVueIcons } from '@freshsolutions/fresh-vue'
import { VuetifyIcons as FreshVueCardsIcons } from '@freshsolutions/fresh-vue-cards'
import { VuetifyIcons as FreshVueFilesIcons } from '@freshsolutions/fresh-vue-files'
import { VuetifyIcons as FreshVueFormsIcons } from '@freshsolutions/fresh-vue-forms'
import { Ripple } from 'vuetify/lib/directives'
import de from 'vuetify/es5/locale/de'
import { detect } from 'detect-browser'

function isIE() {
  const browser = detect()
  return browser && browser.name === 'ie'
}

Vue.use(Vuetify, {
  components: {
    // Import some components here. Otherwise they will throw warnings on build ("Conflicting order between")
    VSelect,
    // Import VExpansionPanels for @freshsolutions/fresh-vue-forms
    VExpansionPanels
  },
  directives: {
    Ripple
  }
})

export default new Vuetify({
  theme: {
    options: {
      customProperties: !isIE() // we can only enable this staff if we are not in internet explorer. See: https://github.com/vuetifyjs/vuetify/issues/8235
    },
    dark: false
    /*
    themes: {
      light: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      }
    }
    */
  },
  lang: {
    locales: { de },
    current: 'de'
  },
  icons: {
    values: {
      ...FreshVueIcons,
      ...FreshVueCardsIcons,
      ...FreshVueFilesIcons,
      ...FreshVueFormsIcons
    }
  }
})
