import store from '@/store'
import log from 'loglevel'
import ThemeUtils from '@/utils/theme.js'
import Vuetify from '@/plugins/vuetify'

/**
 * This will be called once the app is loaded / reloaded (browser refresh)
 */

export default new Promise((resolve, reject) => {
  Promise.resolve(true)
    .then(() => {
      if ('serviceWorker' in navigator) {
        try {
          return navigator.serviceWorker.register('/serviceworker.js')
        } catch (e) {
          log.error('Service worker registration failed!')
        }
      }
    })
    .then(() => {
      /* init client */
      return store.dispatch('loadClient')
    })
    .then(() => {
      /* load theme */

      // listen for changes
      store.watch(
        (state) => state.dark,
        (val) => {
          Vuetify.framework.theme.dark = val
        }
      )

      // watch for changes in the color-scheme
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', () => {
          store.dispatch('loadColorScheme')
        })

      return ThemeUtils.loadTheme()
    })
    .then(() => {
      // do other init steps if necessary
      // ...
      resolve()
    })
    .catch((e) => {
      if (e === 'NO_CLIENT_FOUND' && process.env.VUE_APP_ERROR_REDIRECT_URL) {
        // if there is no client we will redirect the user to the product-page
        window.location.href = process.env.VUE_APP_ERROR_REDIRECT_URL
      } else {
        reject(e)
      }
    })
})
