import router from '@/router'
import introJs from 'intro.js'

import 'intro.js/introjs.css'
import 'intro.js/themes/introjs-modern.css'

export default {
  namespaced: true,

  state: {
    viewed: []
  },

  mutations: {
    reset(state) {
      state.viewed = []
    },

    addViewedPage(state, page) {
      if (!state.viewed.includes(page)) {
        state.viewed.push(page)
      }
    }
  },

  actions: {
    reset({ commit }) {
      commit('reset')
    },

    start({ state, commit, getters }, { force } = {}) {
      const page = router.currentRoute.name

      if (page && (force || !state.viewed.includes(page))) {
        getters.instance.exit()
        getters.instance.start()
        commit('addViewedPage', page)
      }
    }
  },

  getters: {
    instance: () =>
      introJs()
        .setOption('nextLabel', 'Weiter >')
        .setOption('prevLabel', '< Zurück')
        .setOption('skipLabel', 'Überspringen')
        .setOption('doneLabel', 'Fertig')
  }
}
