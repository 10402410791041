<template>
  <v-footer app :absolute="$vuetify.breakpoint.mobile" inset padless>
    <v-row justify="end" no-gutters class="mx-2">
      <template v-if="!signedIn">
        <v-chip label outlined class="my-2" small>
          <version />
        </v-chip>
        <v-spacer />
      </template>
      <v-btn
        v-for="(link, index) in links"
        :key="index"
        color="primary"
        text
        rounded
        x-small
        :href="link.url"
        target="_blank"
        class="my-2"
      >
        {{ link.text }}
      </v-btn>
    </v-row>
  </v-footer>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import Version from '@/components/Version'

export default {
  name: 'AppFooter',

  components: {
    Version
  },

  data() {
    return {
      links: [
        {
          text: this.$t('appfooter.copyright_text'),
          url: 'https://personalmarkt.online'
        },
        {
          text: this.$t('appfooter.licenses_text'),
          url: 'https://personalmarkt.online/licenses'
        },
        {
          text: this.$t('appfooter.privacypolicy_text'),
          url: this.$router.resolve({ name: 'privacypolicy' }).href
        },
        {
          text: this.$t('appfooter.help_text'),
          url: 'https://freshgroup.atlassian.net/l/cp/zYJmoDHv'
        },
        {
          text: this.$t('appfooter.feedback_text'),
          url: 'https://forms.office.com/e/KrZPm0pdsB'
        }
      ]
    }
  },

  computed: {
    ...mapState(['version', 'stage']),
    ...mapGetters('auth', ['signedIn'])
  }
}
</script>
