import moment from 'moment'

const validator = {
  message(field, { _min_, min }) {
    const value = _min_ || min
    const formatted = moment(value, 'HH:mm').format('HH:mm')
    return field + ' muss gleich oder nach ' + formatted + ' Uhr liegen.'
  },
  params: ['min'],
  validate(value, { min }) {
    return min
      ? moment(value, 'HH:mm').isSameOrAfter(moment(min, 'HH:mm'))
      : true
  }
}
export default validator
