import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { apolloClient } from '@/plugins/vue-apollo'
import gql from 'graphql-tag'
import _ from 'lodash'
import log from 'loglevel'

// Utils
import LocationUtils from '@/utils/location'
import ThemeUtils from '@/utils/theme.js'

// modules
import layout from './modules/layout.js'
import auth from './modules/auth.js'
import introjs from './modules/introjs.js'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth', 'introjs']
})

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    version: process.env.VUE_APP_VERSION || 0,
    environment: process.env.NODE_ENV || 'not set',
    stage: process.env.VUE_APP_STAGE || 'not set',

    client: null,
    dark: false
  },
  mutations: {
    setClient(state, client) {
      state.client = client
      log.debug('set client to "' + _.get(client, 'shortname', null) + '"')
    },

    setTheme(state, theme) {
      if (state.client) {
        Vue.set(state.client, 'theme', Object.assign({}, theme))
      }
    },

    setDark(state, dark) {
      state.dark = dark
    }
  },
  actions: {
    loadColorScheme({ commit }) {
      if (window.matchMedia) {
        const dark = !!window.matchMedia('(prefers-color-scheme: dark)').matches
        commit('setDark', dark)
      }
    },

    loadClient({ commit }) {
      return new Promise((resolve, reject) => {
        // get client
        let clientShortname = LocationUtils.getClientFromDomain()

        // get client data from API
        apolloClient
          .query({
            query: gql`
              query client($shortname: String!) {
                client(shortname: $shortname) {
                  id
                  name
                  shortname
                  theme {
                    id
                    image_favicon
                    image_favicon_url
                    image_logo
                    image_logo_url
                    color_primary
                    color_secondary
                    color_accent
                    color_error
                    color_info
                    color_success
                    color_warning
                  }
                }
              }
            `,
            variables: {
              shortname: clientShortname
            }
          })
          .then(({ data: { client } }) => {
            if (client) {
              commit('setClient', client)
              resolve(client)
            } else {
              const errorCode = 'NO_CLIENT_FOUND'
              log.error(errorCode)
              reject(errorCode)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    changeTheme({ commit }, theme) {
      commit('setTheme', theme)
      return ThemeUtils.loadTheme()
    }
  },
  getters: {
    client_id: (state) => _.get(state, 'client.id', null),
    client_shortname: (state) => _.get(state, 'client.shortname', null),
    theme: (state) => _.get(state, 'client.theme', null),
    isDemo: (state, getters) => getters.client_shortname === 'demo'
  },
  modules: {
    layout,
    auth,
    introjs
  },
  plugins: [vuexLocal.plugin]
})

export default store
