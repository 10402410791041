<template>
  <v-list nav dense>
    <v-list-item @click="showIntrojs">
      <v-list-item-action>
        <v-icon small>mdi-help-circle</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{ $t('navigation.showIntrojs') }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      v-if="knowledgeBaseUrl"
      :href="knowledgeBaseUrl"
      target="_blank"
    >
      <v-list-item-action>
        <v-icon small>mdi-help-circle-outline</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{ $t('navigation.knowledgeBase') }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon small>mdi-open-in-new</v-icon>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'AppNavigationListQuickactions',

  computed: {
    knowledgeBaseUrl() {
      return _.get(this.$route, 'meta.knowledgeBaseUrl', null)
    }
  },

  methods: {
    showIntrojs() {
      this.$store.dispatch('introjs/start', { force: true })
    }
  }
}
</script>
