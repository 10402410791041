<template>
  <v-app>
    <!-- navigation staff -->
    <template v-if="!hideNavigation">
      <app-side-sheet v-if="signedIn"></app-side-sheet>
      <app-app-bar v-if="signedIn"></app-app-bar>
      <app-navigation-drawer v-if="signedIn"></app-navigation-drawer>
    </template>

    <!-- content -->
    <app-content></app-content>

    <!-- footer -->
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import AppSideSheet from '@/layout/AppSideSheet'
import AppAppBar from '@/layout/AppAppBar'
import AppNavigationDrawer from '@/layout/AppNavigationDrawer'
import AppContent from '@/layout/AppContent'
import AppFooter from '@/layout/AppFooter'

export default {
  name: 'LayoutWrapper',

  components: {
    AppSideSheet,
    AppAppBar,
    AppNavigationDrawer,
    AppContent,
    AppFooter
  },

  computed: {
    ...mapGetters({
      signedIn: 'auth/signedIn'
    }),

    hideNavigation() {
      return _.get(this.$route, 'meta.layout.hideNavigation', false)
    }
  }
}
</script>
