import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize
} from 'vee-validate'
import {
  required,
  between,
  min,
  max,
  email,
  numeric,
  min_value,
  regex,
  image,
  mimes,
  confirmed,
  is_not
} from 'vee-validate/dist/rules'
import DateFormat from './rules/date_format'
import DateBetween from './rules/date_between'
import DateMin from './rules/date_min'
import DateMinEquals from './rules/date_min_equals'
import DateMax from './rules/date_max'
import DateMaxEquals from './rules/date_max_equals'
import TimeMin from './rules/time_min'
import TimeMinEquals from './rules/time_min_equals'
import TimeMax from './rules/time_max'
import TimeMaxEquals from './rules/time_max_equals'
import Checked from './rules/checked'
import Customform from './rules/customform'
import de from 'vee-validate/dist/locale/de.json'

// Add rules
extend('required', required)
extend('between', between)
extend('min', min)
extend('max', max)
extend('email', email)
extend('numeric', numeric)
extend('min_value', min_value)
extend('regex', regex)
extend('image', image)
extend('mimes', mimes)
extend('confirmed', confirmed)
extend('is_not', is_not)

// Add custom rules
extend('date_format', DateFormat)
extend('date_between', DateBetween)
extend('date_min', DateMin)
extend('date_min_equals', DateMinEquals)
extend('date_max', DateMax)
extend('date_max_equals', DateMaxEquals)
extend('time_min', TimeMin)
extend('time_min_equals', TimeMinEquals)
extend('time_max', TimeMax)
extend('time_max_equals', TimeMaxEquals)
extend('checked', Checked)
extend('customform', Customform)

// install and set language
localize('de', de)

// Register it globally
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
