import Vue from 'vue'
import FreshVue from '@freshsolutions/fresh-vue'
import vuetify from '@/plugins/vuetify'
import { apolloClient } from '@/plugins/vue-apollo'
import i18n from './i18n'

Vue.use(FreshVue, {
  i18n: i18n,
  vuetify: vuetify,
  apollo: apolloClient
})
