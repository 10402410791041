import gql from 'graphql-tag'

export const NAVIGATION_COUNTS_QUERY = gql`
  query counts {
    me {
      id
      absencerequests_count

      client {
        users_count
        candidates_count
        joboffers_count
        groups_count
        absencetypes_count
        absenceallocations_count
        projects_count
        activities_count
        tags_count
        shifts_count
      }
    }
  }
`
