<template>
  <v-alert
    :value="unsupported"
    type="warning"
    prominent
    text
    dismissible
    transition="scale-transition"
    style="width: 100%"
  >
    Dieser Browser wird nicht vollständig unterstützt. Bitte verwenden Sie den
    Google Chrome oder Mozilla Firefox.
  </v-alert>
</template>

<script>
import { detect } from 'detect-browser'

export default {
  name: 'AlertUnsupportedBrowser',

  computed: {
    unsupported() {
      const browser = detect()
      return browser && browser.name === 'ie'
    }
  }
}
</script>
