import Vuetify from '@/plugins/vuetify'
import store from '@/store'
import log from 'loglevel'

export default {
  loadTheme() {
    return new Promise((resolve, reject) => {
      let theme = store.getters['theme']

      if (theme) {
        const themeData = {
          primary: theme.color_primary,
          secondary: theme.color_secondary,
          accent: theme.color_accent,
          error: theme.color_error,
          info: theme.color_info,
          success: theme.color_success,
          warning: theme.color_warning
        }
        this.setLight(themeData)
        this.setDark(themeData)

        store.dispatch('loadColorScheme')

        resolve(theme)
      } else {
        log.error('No theme for client found.')
        reject('No theme for client found.')
      }
    })
  },

  setLight(theme) {
    Vuetify.framework.theme.themes.light = theme
    log.log('Set theme to light', theme)
  },

  setDark(theme) {
    Vuetify.framework.theme.themes.dark = theme
    log.log('Set theme to dark', theme)
  },

  getLight() {
    return Vuetify.framework.theme.themes.light
  },

  getDark() {
    return Vuetify.framework.theme.themes.dark
  }
}
