var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card-base',_vm._b({attrs:{"loading":_vm.internalLoading,"disabled":_vm.internalLoading}},'card-base',_vm.$attrs,false),[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"color":"primary","fab":"","large":!_vm.dense,"small":!!_vm.dense},on:{"click":_vm.onSubmit}},[(_vm.running)?_c('v-icon',[_vm._v("mdi-stop")]):_c('v-icon',[_vm._v("mdi-play")])],1)],1),(_vm.running)?_c('v-col',{class:[
        'grow',
        { 'text-body-1': !_vm.dense },
        { 'text-caption': !!_vm.dense }
      ]},[_vm._v(" "+_vm._s(_vm._f("dateAndTime")(_vm.timerecord.start))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('timerecorder.duration'))+": "+_vm._s(_vm._f("timer")(_vm.timeSinceStart))+" ")]):_c('v-col',{class:[
        'grow',
        { 'text-body-1': !_vm.dense },
        { 'text-caption': !!_vm.dense }
      ]},[_vm._v(" "+_vm._s(_vm.$t('timerecorder.not-running-info'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }