import log from 'loglevel'
import AuthApi from '@/api/auth.js'
import { apolloClient } from '@/plugins/vue-apollo'

export default {
  namespaced: true,

  state: {
    token: null,

    user: null,
    roles: null,
    permissions: null
  },

  mutations: {
    setToken(state, token) {
      state.token = token
    },

    removeToken(state) {
      state.token = null

      apolloClient.resetStore()
    },

    setUser(state, user) {
      state.user = user
    },

    setRoles(state, roles) {
      state.roles = roles
    },

    setPermissions(state, permissions) {
      state.permissions = permissions
    }
  },

  actions: {
    login({ commit }, { email, password, token }) {
      return new Promise((resolve, reject) => {
        AuthApi.login(token ? { token } : { email, password })
          .then((res) => {
            commit('setToken', res.access_token)

            /* TODO get user from response, reponse should return the user better. Maybe graphql login? */
            commit('setUser', res.user)
            commit('setRoles', res.roles)
            commit('setPermissions', res.permissions)

            resolve()
          })
          .catch((err) => {
            if (err.status !== 401) {
              // Only log error if it isn't a simple "wrong password or username" error
              log.error(err)
            }

            commit('removeToken')
            reject(err)
          })
      })
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        AuthApi.logout()
          .then(() => {
            commit('removeToken')
            commit('setUser', null)

            resolve(true)
          })
          .catch((err) => {
            log.error(err)

            commit('removeToken')
            commit('setUser', null)

            reject(err)
          })
      })
    }
  },

  getters: {
    user: (state) => state.user,
    signedIn: (state) => !!state.token,
    roles: (state, getters) => (getters.signedIn ? state.roles : null),
    permissions: (state, getters) =>
      getters.signedIn ? state.permissions : null,
    hasRole: (state, getters) => (role) =>
      getters.signedIn ? (state.roles || []).includes(role) : false,
    hasPermission: (state, getters) => (permission) =>
      getters.signedIn ? (state.permissions || []).includes(permission) : false,
    isSuperAdministrator: (state, getters) =>
      getters.signedIn && getters.hasRole('SUPER_ADMINISTRATOR'),
    isAdministrator: (state, getters) =>
      getters.signedIn && getters.hasRole('ADMINISTRATOR'),
    isStaff: (state, getters) => getters.signedIn && getters.hasRole('STAFF'),
    isTrainer: (state, getters) =>
      getters.signedIn && getters.hasRole('TRAINER'),
    isEmployee: (state, getters) =>
      getters.signedIn && getters.hasRole('EMPLOYEE'),
    canReviewAbsences: (state, getters) =>
      getters.signedIn && getters.hasPermission('absencerequests')
  }
}
