import 'whatwg-fetch'

import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import bootstrap from '@/bootstrap.js'
import '@/plugins/loglevel.js'
import '@/plugins/vee-validate/vee-validate.js'
import '@/plugins/axios.js'
import '@/plugins/vue-the-mask.js'
import '@/plugins/vue-meta.js'
import '@/plugins/vue-toasted.js'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import '@/plugins/fresh-vue.js'
import '@/plugins/fresh-vue-cards.js'
import '@/plugins/fresh-vue-files.js'
import '@/plugins/fresh-vue-forms.js'
import { apolloProvider } from '@/plugins/vue-apollo'
import log from 'loglevel'
import '@/filter/index'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false

// start initializing the app
bootstrap
  .then(() => {
    log.debug('bootstrap finished')
  })
  .catch((e) => {
    log.debug('bootstrap failed', e)
  })

new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider,
  render: (h) => h(App)
}).$mount('#app')
