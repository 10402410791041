<template>
  <!-- render appBar from vue-router, if there is one -->
  <router-view v-if="renderRouterView" name="appBar"></router-view>

  <!-- otherwise render a default app-bar -->
  <v-app-bar
    v-else
    app
    clipped-right
    :hide-on-scroll="$vuetify.breakpoint.mobile"
  >
    <template #img>
      <app-logo height="48px" class="my-2"></app-logo>
    </template>

    <v-app-bar-nav-icon
      @click.stop="$store.dispatch('layout/toggleNavigationDrawer')"
    />

    <v-toolbar-title v-if="!$vuetify.breakpoint.mobile">
      {{ title }}
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import _ from 'lodash'

import AppLogo from '@/layout/AppLogo'

export default {
  name: 'AppAppBar',

  components: {
    AppLogo
  },

  computed: {
    renderRouterView() {
      return !!_.get(this.$route, 'matched[0].components.appBar', false)
    },
    title() {
      return _.get(this.$route, 'meta.title', '')
    }
  }
}
</script>
