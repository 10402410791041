import moment from 'moment'

const utils = {
  ranges: {
    last(number, type, mode = 'AS_DATE') {
      const start = moment().subtract(number, type).startOf(type)
      const end = moment().endOf(type)

      switch (mode) {
        case 'AS_STRING':
          return {
            start: utils.getDateAsString(start.toDate()),
            end: utils.getDateAsString(end.toDate())
          }
        case 'AS_MOMENT':
          return {
            start: start,
            end: end
          }
        case 'AS_DATE':
        default:
          return {
            start: start.toDate(),
            end: end.toDate()
          }
      }
    },

    current(type, mode) {
      return utils.ranges.last(0, type, mode)
    }
  },

  getDatesBetween(start, end, mode = 'day') {
    if (typeof end === 'string') {
      end = new Date(end)
    } else {
      end = end || new Date()
    }

    let dates = []
    let current = new Date(start)
    while (current <= end) {
      dates.push(new Date(current))
      switch (mode) {
        case 'year':
          current.setYear(current.getYear() + 1)
          break
        case 'month':
          current.setMonth(current.getMonth() + 1)
          break
        case 'day':
        default:
          current.setDate(current.getDate() + 1)
          break
      }
    }
    return dates
  },

  getDateAsString(date) {
    if (typeof date === 'string') {
      date = new Date(date)
    } else {
      date = date || new Date()
    }

    const day = date.getDate() + '' // as string
    const month = date.getMonth() + 1 + '' // as string
    const year = date.getFullYear() + '' // as string

    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  },

  getFirstDayOfWeek(date) {
    return moment(date).clone().weekday(1).toDate() // monday
  },

  getLastDayOfWeek(date) {
    return moment(date).clone().weekday(7).toDate() // sunday
  },

  getFirstDayOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1)
  },

  getLastDayOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999)
  },

  getFirstDayOfYear(date) {
    return new Date(date.getFullYear(), 0, 1)
  },

  getLastDayOfYear(date) {
    return new Date(date.getFullYear(), 11, 31, 23, 59, 59, 999)
  },

  getDurationAsString(seconds) {
    const minutes = seconds / 60
    const hours = Math.floor(minutes / 60) + ''
    const remainingMinutes = Math.floor(minutes % 60) + ''

    return `${hours.padStart(2, '0')}:${remainingMinutes.padStart(2, '0')}`
  },

  getDateAndTimeString(date) {
    if (typeof date === 'string') {
      date = new Date(date)
    } else {
      date = date || new Date()
    }

    const dateString = this.getDateAsString(date)
    const hours = (date.getHours() + '').padStart(2, '0')
    const minutes = (date.getMinutes() + '').padStart(2, '0')
    const seconds = (date.getSeconds() + '').padStart(2, '0')

    return `${dateString} ${hours}:${minutes}:${seconds}`
  },

  isTimeBetween(date, start, end) {
    const startHours = start.substring(0, 2)
    const startMinutes = start.substring(3, 5)
    const startSeconds = start.substring(6, 8)
    const endHours = end.substring(0, 2)
    const endMinutes = end.substring(3, 5)
    const endSeconds = end.substring(6, 8)
    const now = new Date()
    let startDate = new Date(date)
    startDate.setHours(startHours, startMinutes, startSeconds)
    let endDate = new Date(date)
    endDate.setHours(endHours, endMinutes, endSeconds)

    return (
      now.getTime() > startDate.getTime() && now.getTime() < endDate.getTime()
    )
  },

  isSameDate(date1, date2) {
    if (!date1 || !date2) return false

    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    )
  }
}

export default utils
