import moment from 'moment'

const validator = {
  message(field, { _max_, max }) {
    const value = _max_ || max
    const formatted = moment(value, 'HH:mm').format('HH:mm')
    return field + ' muss vor ' + formatted + ' Uhr liegen.'
  },
  params: ['max'],
  validate(value, { max }) {
    return max ? moment(value, 'HH:mm').isBefore(moment(max, 'HH:mm')) : true
  }
}
export default validator
