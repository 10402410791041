<template>
  <div>
    <app-logo class="ma-3"></app-logo>

    <v-list-item two-line @click="showProfile = !showProfile">
      <v-list-item-avatar>
        <v-icon color="primary">mdi-account-circle</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          <span v-if="userName">{{ userName }}</span>
          <span v-else class="font-italic">{{ $t('misc.unknown') }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="userEmail">{{ userEmail }}</span>
          <span v-else class="font-italic">{{
            $t('misc.unknown')
          }}</span></v-list-item-subtitle
        >
      </v-list-item-content>

      <v-list-item-action>
        <v-icon :class="{ rotate180: showProfile }">mdi-menu-down</v-icon>
      </v-list-item-action>
    </v-list-item>

    <!-- Profile Navigation -->
    <v-divider v-if="showProfile"></v-divider>
    <v-expand-transition>
      <app-navigation-list-user v-if="showProfile"></app-navigation-list-user>
    </v-expand-transition>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import AppLogo from './AppLogo'
import AppNavigationListUser from './AppNavigationListUser'

export default {
  name: 'AppNavigationProfile',

  components: {
    AppLogo,
    AppNavigationListUser
  },

  data() {
    return {
      showProfile: false
    }
  },

  computed: {
    ...mapGetters({
      signedIn: 'auth/signedIn',
      user: 'auth/user'
    }),

    userName() {
      return _.get(this.user, 'name', null)
    },

    userEmail() {
      return _.get(this.user, 'email', null)
    },

    // vuex two-way binding
    navigationDrawer: {
      get() {
        return this.$store.state.layout.navigationDrawer
      },
      set(val) {
        this.$store.commit('layout/setNavigationDrawer', val)
      }
    }
  }
}
</script>
