<template>
  <v-navigation-drawer v-model="navigationDrawer" app flat>
    <!-- Profile -->
    <app-navigation-profile />

    <!-- Timerecorder -->
    <v-divider />
    <timerecorder dense flat />

    <!-- Navigation -->
    <v-divider />
    <app-navigation-list-main />

    <!-- Reset Help -->
    <v-divider />
    <app-navigation-list-quickactions />

    <!-- Theme -->
    <v-divider />
    <v-container>
      <v-row align="center">
        <v-col cols="12" class="text-center">
          <theme-switcher dense />
        </v-col>
      </v-row>
    </v-container>

    <template #append>
      <!-- Theme -->
      <v-divider />

      <v-toolbar flat dense>
        <version class="text-caption" />
      </v-toolbar>
    </template>
  </v-navigation-drawer>
</template>

<script>
import AppNavigationProfile from './AppNavigationProfile'
import Timerecorder from '@/components/Timerecorder'
import AppNavigationListMain from './AppNavigationListMain'
import AppNavigationListQuickactions from './AppNavigationListQuickactions'
import ThemeSwitcher from '@/components/ThemeSwitcher'
import Version from '@/components/Version'

export default {
  name: 'AppNavigationDrawer',

  components: {
    AppNavigationProfile,
    Timerecorder,
    AppNavigationListMain,
    AppNavigationListQuickactions,
    ThemeSwitcher,
    Version
  },

  computed: {
    // vuex two-way binding
    navigationDrawer: {
      get() {
        return this.$store.state.layout.navigationDrawer
      },
      set(val) {
        this.$store.commit('layout/setNavigationDrawer', val)
      }
    }
  }
}
</script>
