<template>
  <layout-wrapper v-if="showView"></layout-wrapper>
  <div v-else-if="showLoading">
    <v-app>
      <v-container class="fill-height">
        <v-row justify="center" align="center">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </v-container>
    </v-app>
  </div>
  <div v-else>
    <v-app>
      <v-container>
        <v-alert type="error">Error</v-alert>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import bootstrap from '@/bootstrap.js'
import log from 'loglevel'

import LayoutWrapper from '@/views/LayoutWrapper'

const STATE = {
  OK: 'OK',
  LOADING: 'LOADING',
  ERROR: 'ERROR'
}

export default {
  name: 'App',

  components: {
    LayoutWrapper
  },

  metaInfo() {
    return {
      title: this.title,
      titleTemplate: this.$t('title') + ' | %s',

      link: [
        { rel: 'icon', href: this.faviconSrc },
        { rel: 'apple-touch-icon', href: this.faviconSrc },
        { rel: 'manifest', href: `/manifest.json` }
      ].filter(Boolean),
      meta: [{ name: 'theme-color', content: this.theme?.color_primary }]
    }
  },

  data() {
    return {
      state: STATE.LOADING
    }
  },

  computed: {
    ...mapGetters({
      theme: 'theme'
    }),

    showView() {
      return this.state === STATE.OK
    },
    showLoading() {
      return this.state === STATE.LOADING
    },
    title() {
      return _.get(this.$route, 'meta.title', '')
    },
    faviconSrc() {
      return _.get(
        this.theme,
        'image_favicon_url',
        process.env.BASE_URL + 'favicon.ico'
      )
    }
  },

  mounted() {
    log.info("App version: '" + this.$store.state.version + "'")
    log.info("App node-environment: '" + this.$store.state.environment + "'")
    log.info("App stage: '" + this.$store.state.stage + "'")

    this.state = STATE.LOADING

    bootstrap
      .then(() => {
        this.state = STATE.OK
      })
      .catch(() => {
        this.state = STATE.ERROR
      })
  }
}
</script>

<style lang="scss">
@import 'src/scss/index.scss';
</style>
