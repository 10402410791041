<template>
  <v-img v-if="src" :src="src" v-bind="$attrs" contain></v-img>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppLogo',

  // This doesn't need to be reactive, so we will define it in the $options.
  defaultSrc: process.env.BASE_URL + 'logo.png',

  computed: {
    ...mapGetters({
      theme: 'theme'
    }),

    src() {
      return (this.theme || {}).image_logo_url || this.$options.defaultSrc
    }
  }
}
</script>
